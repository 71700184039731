import React, { useCallback, useEffect, useMemo, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { AnimatePresence } from "framer-motion";

import { PersonaType, Questions } from "./data";
import * as styles from "./Quiz.module.scss";
import { Question } from "./Question";
import { Result } from "./Result";
import { useSiteContext } from "../SiteContext";
import { PersonaResult } from "../../utils/types";

interface PersonasData {
  personas: {
    edges: Array<{
      node: PersonaResult;
    }>;
  };
}

const Quiz: React.FC<{ exhibition?: boolean }> = ({ exhibition }) => {
  const { currentQuestion, setCurrentQuestion } = useSiteContext();

  const data = useStaticQuery<PersonasData>(graphql`
    query PersonasQuery {
      personas: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/personas/" } }
      ) {
        edges {
          node {
            frontmatter {
              title
              tagline
              facts
              recipe
              audio {
                publicURL
              }
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            fields {
              persona
            }
            rawMarkdownBody
          }
        }
      }
    }
  `);

  const [currentAnswers, setCurrentAnswers] = useState<PersonaType[]>([]);

  const currentPersona = useMemo(() => {
    // return data.personas.edges.find(
    //   ({ node }) => node.fields.persona === "saladspinner",
    // );

    if (currentAnswers.length === 0) {
      return false;
    }

    if (currentAnswers[0] === PersonaType.SaladSpinner) {
      return data.personas.edges.find(
        ({ node }) => node.fields.persona === "saladspinner",
      );
    }

    const counts = Object.entries(
      currentAnswers.reduce<Record<PersonaType, number>>(
        (prev, curr) => {
          if (!prev[curr]) {
            prev[curr] = 0;
          }

          prev[curr]++;
          return prev;
        },
        {
          [PersonaType.AdventurousEater]: 0,
          [PersonaType.BLT]: 0,
          [PersonaType.ChickenSupreme]: 0,
          [PersonaType.HappyEater]: 0,
          [PersonaType.PartTimeCarnivore]: 0,
          [PersonaType.SaladSpinner]: 0,
        },
      ),
    ).sort((a, b) => b[1] - a[1]);

    const getWinner = () => {
      let target = counts[0][0];

      if (
        target === PersonaType.SaladSpinner &&
        counts[0][1] === counts[1][1]
      ) {
        target = counts[1][0];
      }

      return target;
    };

    return data.personas.edges.find(
      ({ node }) => node.fields.persona === getWinner(),
    );
  }, [currentAnswers.length, data]);

  const chooseAnswer = useCallback((ans: PersonaType) => {
    setCurrentAnswers((a) => [...a, ans]);
    setCurrentQuestion((s) => s + 1);
  }, []);

  const restartQuiz = useCallback(() => {
    setCurrentAnswers([]);
    setCurrentQuestion(0);
  }, []);

  const isFinished = currentQuestion >= Questions.length && currentPersona;
  // const isFinished = true;

  return (
    <div className={styles.outer}>
      <AnimatePresence exitBeforeEnter>
        {isFinished && currentPersona && (
          <Result
            key="result"
            currentPersona={currentPersona.node}
            restartQuiz={restartQuiz}
            exhibition={exhibition}
          />
        )}
        {!isFinished && (
          <Question
            key="question"
            chooseAnswer={chooseAnswer}
            currentQuestion={currentQuestion}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export { Quiz };

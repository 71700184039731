import { useEffect, useState } from "react";

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

const useIdleTimer = (time: number): boolean => {
  const [isIdle, setIsIdle] = useState(false);

  useEffect(() => {
    let timeoutId;

    const onTrigger = debounce(() => {
      setIsIdle(false);

      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setIsIdle(true);
      }, time);
    }, 500);
    onTrigger();

    document.addEventListener("mousemove", onTrigger);
    document.addEventListener("click", onTrigger);
    document.addEventListener("touchstart", onTrigger);
    document.addEventListener("keypress", onTrigger);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [time]);

  return isIdle;
};

export { useIdleTimer };

import React, { useCallback, useEffect, useState } from "react";
import {
  motion,
  Variants,
  AnimatePresence,
  AnimateSharedLayout,
} from "framer-motion";
import { Markdown } from "../../Markdown";
import { PersonaResult } from "../../../utils/types";

import { ReactComponent as BurgerSVG } from "../../../images/burger.svg";

import * as styles from "./Result.module.scss";
import classNames from "classnames";
import { ResultMore } from "../ResultMore";
import { registerExits } from "../../../utils/utils";
import { DecorativeCircle } from "../../DecorativeCircle";
import { useMediaQuery } from "../../../utils/hooks/mediaQuery";
import { useSiteContext } from "../../SiteContext";
import { sendEvent } from "../../../utils/analytics";

interface ResultProps {
  restartQuiz: () => void;
  currentPersona: PersonaResult;
  exhibition?: boolean;
}

const ResultVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

enum Stage {
  Loading = "loading",
  Display = "display",
}

const Result: React.FC<ResultProps> = ({
  restartQuiz,
  currentPersona,
  exhibition,
}) => {
  const { currentSection } = useSiteContext();

  const [stage, setStage] = useState<Stage>(Stage.Loading);
  const [showMore, setShowMore] = useState(false);
  const [selectedFact, setSelectedFact] = useState(-1);

  useEffect(() => {
    setTimeout(() => {
      setStage(Stage.Display);
    }, 2500);
  }, []);

  useEffect(() => {
    if (selectedFact >= 0) {
      return registerExits(() => {
        setSelectedFact(-1);
      });
    }
  }, [selectedFact]);

  useEffect(() => {
    setSelectedFact(-1);
  }, [currentSection]);

  useEffect(() => {
    sendEvent(
      `${exhibition ? `result-exhibition` : "result"}-${
        currentPersona.frontmatter.title
      }`,
      {
        persona: currentPersona.frontmatter.title,
      },
    );
  }, [currentPersona, exhibition]);

  return (
    <motion.div
      animate="show"
      initial="hidden"
      exit="exit"
      variants={ResultVariants}
      className={classNames(
        styles.outer,
        { [styles.exhibition]: exhibition },
        currentPersona.fields.persona,
      )}
    >
      <div className={styles.circlePosition}>
        <motion.div
          initial={{ scale: 0, rotate: "-180deg" }}
          animate={{ scale: 1, rotate: "0deg" }}
          transition={{ duration: 2 }}
          className={styles.centreCircle}
        >
          <AnimatePresence exitBeforeEnter>
            {stage === Stage.Loading && (
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={styles.loading}
              >
                You are...
              </motion.p>
            )}
            {stage === Stage.Display && (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <motion.div
                  initial={{ opacity: 1 }}
                  animate={{ opacity: exhibition ? 1 : 0 }}
                  transition={{ delay: 2.5 }}
                  className={styles.centreCircleOverlay}
                >
                  <div className={styles.centreCircleTitle}>
                    {currentPersona.frontmatter.title.split(" ").map((v) => (
                      <span key={v}>{v}</span>
                    ))}
                  </div>

                  <div className={styles.centreCircleTagline}>
                    {currentPersona.frontmatter.tagline
                      .split(" - ")
                      .map((v) => (
                        <span key={v}>{v}</span>
                      ))}
                  </div>
                </motion.div>
                <div className={styles.centreCircleTitle}>
                  {currentPersona.frontmatter.title.split(" ").map((v) => (
                    <span key={v}>{v}</span>
                  ))}
                </div>
                <div className={styles.centreCircleDescription}>
                  <Markdown>{currentPersona.rawMarkdownBody}</Markdown>
                </div>
                <div className={styles.centreCircleCta}>
                  <p>
                    LEAP researchers have been studying the effects of eating
                    meat on our bodies and the planet. Click on the buttons to
                    find out what they have discovered...
                  </p>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </div>

      {stage === Stage.Display && (
        <>
          {!exhibition && (
            <AnimateSharedLayout>
              {currentPersona.frontmatter.facts.map((fact, id) => (
                <motion.button
                  key={`fact-${id}`}
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ delay: id * 0.15 + 1 }}
                  layoutId={`fact-${id}`}
                  className={classNames(styles.fact, styles[`fact${id}`])}
                  onClick={() => setSelectedFact(id)}
                >
                  DID YOU KNOW?
                </motion.button>
              ))}

              <div
                className={classNames(styles.selectedFactOverlay, {
                  [styles.active]: selectedFact >= 0,
                })}
                onClick={() => setSelectedFact(-1)}
              />

              <AnimatePresence>
                {selectedFact >= 0 && (
                  <motion.div
                    layoutId={`fact-${selectedFact}`}
                    transition={{
                      type: "spring",
                      stiffness: 500,
                      damping: 30,
                    }}
                    className={classNames(
                      styles.selectedFact,
                      styles[`selectedFact${selectedFact}`],
                    )}
                    onClick={() => setSelectedFact(-1)}
                  >
                    <h2>Did you know?</h2>
                    <Markdown>
                      {currentPersona.frontmatter.facts[selectedFact]}
                    </Markdown>
                  </motion.div>
                )}
              </AnimatePresence>
            </AnimateSharedLayout>
          )}

          {exhibition && (
            <>
              <motion.button
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: 1.3 }}
                className={classNames(styles.fact, styles[`fact4`])}
                onClick={() => restartQuiz()}
              >
                RESTART THE QUIZ
              </motion.button>
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: 1.15 }}
                className={classNames(styles.fact, styles[`fact3`])}
              >
                Pick up your persona card!
              </motion.div>
            </>
          )}
        </>
      )}

      <DecorativeCircle
        className={classNames(styles.dec, styles.decOne)}
        pattern="004"
      />
      <DecorativeCircle
        className={classNames(styles.dec, styles.decTwo)}
        pattern="005"
      />
      <DecorativeCircle
        className={classNames(styles.dec, styles.decThree)}
        pattern="002"
      />

      {!exhibition && (
        <motion.div
          className={classNames(styles.more, { [styles.active]: showMore })}
          initial={{ scale: 0, rotate: "-5deg" }}
          animate={stage === Stage.Display ? { scale: 1, rotate: "10deg" } : {}}
          transition={{ delay: 1 }}
          onClick={() => setShowMore(!showMore)}
        >
          <BurgerSVG />

          <span className={styles.moreTag}>
            {showMore ? "Back" : "Find out more"}
          </span>
        </motion.div>
      )}

      {showMore && !exhibition && (
        <ResultMore
          image={currentPersona.frontmatter.image}
          recipe={currentPersona.frontmatter.recipe}
          audio={currentPersona.frontmatter.audio.publicURL}
        />
      )}
    </motion.div>
  );
};

export { Result };

// extracted by mini-css-extract-plugin
export var outer = "Result-module--outer--3o741";
export var circlePosition = "Result-module--circle-position--2DXho";
export var centreCircle = "Result-module--centre-circle--1K-tS";
export var centreCircleTitle = "Result-module--centre-circle-title--1uGLG";
export var centreCircleTagline = "Result-module--centre-circle-tagline--MdOTZ";
export var centreCircleDescription = "Result-module--centre-circle-description--3xEQa";
export var centreCircleCta = "Result-module--centre-circle-cta--2XsAt";
export var centreCircleOverlay = "Result-module--centre-circle-overlay--3GQ_y";
export var exhibition = "Result-module--exhibition--vUUwm";
export var loading = "Result-module--loading--3obsf";
export var more = "Result-module--more--1Ceyw";
export var active = "Result-module--active--3ogwz";
export var moreTag = "Result-module--more-tag--1q0Lw";
export var fact = "Result-module--fact--2cNGz";
export var selectedFact = "Result-module--selected-fact--2RLTZ";
export var fact0 = "Result-module--fact0--2vqFD";
export var selectedFact0 = "Result-module--selected-fact0--39gIP";
export var fact1 = "Result-module--fact1--F3otw";
export var selectedFact1 = "Result-module--selected-fact1--3lqEf";
export var fact2 = "Result-module--fact2--37D7z";
export var selectedFact2 = "Result-module--selected-fact2--3nNFN";
export var fact3 = "Result-module--fact3--3EjwT";
export var selectedFact3 = "Result-module--selected-fact3--2Jf38";
export var fact4 = "Result-module--fact4--1JSis";
export var selectedFact4 = "Result-module--selected-fact4--3rqqQ";
export var selectedFactOverlay = "Result-module--selected-fact-overlay--3u9wK";
export var dec = "Result-module--dec--3_6rz";
export var decOne = "Result-module--dec-one--2lDJz";
export var decTwo = "Result-module--dec-two--133sw";
export var decThree = "Result-module--dec-three--U1QkS";
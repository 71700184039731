// extracted by mini-css-extract-plugin
export var outer = "ResultMore-module--outer--2Orbg";
export var pool = "ResultMore-module--pool--16Tu7";
export var poolAudio = "ResultMore-module--pool-audio--3qNbR";
export var poolAudioPlayer = "ResultMore-module--pool-audio-player--UNTh-";
export var poolChallenge = "ResultMore-module--pool-challenge--35iq_";
export var poolRecipe = "ResultMore-module--pool-recipe--3iPa7";
export var poolCta = "ResultMore-module--pool-cta--2kIij";
export var cta = "ResultMore-module--cta--kX3gR";
export var recipe = "ResultMore-module--recipe--18_Gs";
export var challenge = "ResultMore-module--challenge--PyLH_";
export var challengeText = "ResultMore-module--challenge-text--2pMi8";
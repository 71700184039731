import classNames from "classnames";
import React, { useCallback, useEffect, useRef } from "react";
import { UnlistenFunction } from "../../utils/events";
import { useSiteContext } from "../SiteContext";

import { main, fixed, free } from "./Page.module.scss";

interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  fixed?: boolean;
  id: string;
  free?: boolean;
}

const Page: React.FC<PageProps> = ({
  children,
  className,
  fixed: isFixed,
  free: isFree,
  ...props
}) => {
  const { registerSection } = useSiteContext();
  const unregister = useRef<UnlistenFunction>(null);

  const onSectionRef = useCallback((el: HTMLDivElement | null) => {
    if (el) {
      unregister.current = registerSection(el);
    }
  }, []);

  useEffect(() => {
    return () => {
      unregister.current();
    };
  }, []);

  return (
    <section
      ref={onSectionRef}
      className={classNames(
        main,
        { [fixed]: isFixed, [free]: isFree },
        className,
      )}
      {...props}
    >
      {children}
    </section>
  );
};

export { Page };

import React, { useEffect } from "react";
import { useIdleTimer } from "../../../utils/hooks/idleTimer";

import { Page } from "../../Page";
import { Quiz } from "../../Quiz";

import { main } from "./Quiz.module.scss";

const QuizPage: React.FC<{ exhibition?: boolean }> = ({ exhibition }) => {
  const isIdle = useIdleTimer(1000 * 60 * 30);

  useEffect(() => {
    if (exhibition && isIdle) {
      window.location.reload();
    }
  }, [exhibition, isIdle]);

  return (
    <Page id="quiz" className={main}>
      <Quiz exhibition={exhibition} />
    </Page>
  );
};

export { QuizPage };

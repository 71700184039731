import React, { useMemo } from "react";
import { AnimatePresence, motion, Variants } from "framer-motion";

import { PersonaType, Questions } from "../data";
import * as styles from "./Question.module.scss";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
    },
  },
  exit: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const item = {
  hidden: { opacity: 0, y: -10 },
  show: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
};

const QuestionVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

interface QuestionProps {
  currentQuestion: number;
  chooseAnswer: (answer: PersonaType) => void;
}

const Question: React.FC<QuestionProps> = ({
  currentQuestion,
  chooseAnswer,
}) => {
  const questionContent = useMemo(() => Questions[currentQuestion], [
    currentQuestion,
  ]);

  if (!questionContent) {
    return null;
  }

  return (
    <motion.div
      animate="show"
      initial="hidden"
      exit="exit"
      variants={QuestionVariants}
      className={styles.outer}
    >
      <h3 className={styles.questionSubtitle}>
        QUIZ TIME! What kind of meat eater are you?
      </h3>
      <h2 className={styles.questionTitle}>{questionContent.label}</h2>

      <div className={styles.answersWrapper}>
        <AnimatePresence exitBeforeEnter>
          <motion.div
            variants={container}
            initial="hidden"
            animate="show"
            exit="exit"
            className={styles.answersList}
            key={currentQuestion}
          >
            {questionContent.answers.map((answer) => (
              <motion.div variants={item} key={answer.label}>
                <button
                  className={answer.value}
                  onClick={() => chooseAnswer(answer.value)}
                >
                  <span>{answer.label}</span>
                </button>
              </motion.div>
            ))}
          </motion.div>
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export { Question };

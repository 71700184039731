import { GatsbyImageProps } from "gatsby-plugin-image";
import React, { useCallback } from "react";
import { AudioCircle } from "../../AudioCircle";
import { useSiteContext } from "../../SiteContext";

import * as styles from "./ResultMore.module.scss";

interface ResultMoreProps {
  recipe: string;
  image: GatsbyImageProps["image"];
  audio: string;
}

const ResultMore: React.FC<ResultMoreProps> = ({ recipe, image, audio }) => {
  const { setRecipe, setActiveLightboxItem } = useSiteContext();

  const onRef = useCallback((el: HTMLElement | null) => {
    if (el) {
      const y =
        el.getBoundingClientRect().y -
        document.querySelector("header").getBoundingClientRect().height;

      window.scrollBy(0, y);
    }
  }, []);

  return (
    <div ref={onRef} className={styles.outer}>
      <h2>We've chosen the resources just for you...</h2>

      <div className={styles.pool}>
        <div className={styles.poolAudio}>
          <AudioCircle audio={audio} className={styles.poolAudioPlayer} />
        </div>
        <div className={styles.poolChallenge}>
          <button
            onClick={() => setActiveLightboxItem({ image })}
            className={styles.challenge}
          >
            <span className={styles.challengeText}>
              Fancy a
              <br />
              challenge?
            </span>
          </button>
        </div>

        <div className={styles.poolRecipe}>
          <button onClick={() => setRecipe(recipe)} className={styles.recipe}>
            <span>
              Try a<br />
              meat free
              <br />
              Recipe!
            </span>
          </button>
        </div>

        <div className={styles.poolCta}>
          <a href="#resources" className={styles.cta}>
            Find more recipes
            <br />
            and challenges here
          </a>
        </div>
      </div>
    </div>
  );
};

export { ResultMore };
